export const useWorkspaceStore = defineStore('workspace', () => {
    const workspace = ref({
        workspaceID: -1,
        name: '',
        logo: '',
        accountManager: {
            name: '',
            surname: '',
            displayName: '',
            personal: {
                tg: '',
                tel: '',
                email: '',
                role: ''
            }
        },
        balance: {
            accountNumber: '',
            accountKey: '',
            network: '',
            value: -1,
            currency: '',
            currencyCode: -1,
            status: -1,
            expires: '',
            income: -1,
            outcome: -1,
            usdtValue: -1,
            availableForWithdrawal: -1,
        },
        tariff: {
            name: '',
            fee_info: null,
            fee_top_up: 0,
            fee_withdrawal: 0,
            fee_settlement: 0,
        },
        timezone: 0,
        deposit: 0,
    });

    const accountBalance = computed(() => {
        return {
            value: workspace.value.balance.value,
            status: workspace.value.balance.status === 0 ? 'inactive' : 'active',
            expires: `${Math.floor((new Date(workspace.value.balance.expires) - new Date()) / 1000 / 60 / 60 / 24)} days`,
            accountNumber: workspace.value.balance.accountNumber,
            accountKey: workspace.value.balance.accountKey,
            accountKeyShortened: `**${workspace.value.balance.accountKey.slice(-4)}`,
            currency: workspace.value.balance.currency,
            income: workspace.value.balance.income,
            outcome: workspace.value.balance.outcome,
            usdtValue: workspace.value.balance.usdtValue
        }
    });

    const accountManager = computed(() => {
        return workspace.value.accountManager;
    })

    async function fetchWorkspace(workspaceId: string) {
        const workspaceInfo = await getServerResource<typeof workspace.value>('/workspaces', workspaceId);

        workspace.value = workspaceInfo;

        return workspaceInfo;
    }

    return {workspace, accountBalance, accountManager, fetchWorkspace};
})
